import type { FC } from 'react';

import { createRenderer, isSSR } from '@/react-renderer';

export const getRenderFromLayout = <T>(layout: string, pagesLayouts: { [key: string]: string[] } = {}) => {
	const entries = Object.entries(pagesLayouts);
	const name: string =
		entries
			.filter(e => e[1]?.includes(layout))
			?.shift()
			?.shift()
			?.toString() ?? 'home';
	return name as T;
};

export const renderPages = (App: FC<any>) => {
	const render = createRenderer(App);
	// @ts-ignore
	if (!isSSR()) {
		const json = document.getElementById('json-root')?.innerHTML;
		const rootProps = json ? JSON.parse(json) : {};

		render(rootProps);
	}
	return render;
};
