import clientRenderer from './react-renderer.client';
import serverRenderer from './react-renderer.ssr';

export const isSSR = () => import.meta.env.SSR;

export function createRenderer(rootComponent: any) {
	// @ts-ignore
	const renderer = isSSR() ? serverRenderer : clientRenderer;
	return renderer(rootComponent);
}
